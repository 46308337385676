import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { DashboardService } from '../../../pages/components/_core/service/dashboard.service';
import { Dashboard_ITService } from '../../../pages/components/_core/service/dashboard.IT.service';
import { ToastrManager } from 'ng6-toastr-notifications';

@Component({
  selector: 'm-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss']
})
export class NewPasswordComponent implements OnInit {
  viewLoading: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<NewPasswordComponent>,
    private _formBuilder: FormBuilder, 
    private dashboardIT_service: Dashboard_ITService,
    public toastr: ToastrManager,
		@Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  glb_ruc: string = ''; glb_usuario: string = '';

  isSave: boolean = false;
  formMACRO: FormGroup;
  usuario: string;

  ngOnInit() {
    this.glb_ruc = localStorage.getItem('RUCEmpresa').replace(/['"]+/g, '');
    this.glb_usuario = localStorage.getItem('usuario').replace(/['"]+/g, '');
    this.createForm();
  }

  total_register : number = 0; no_error: boolean = true; LOCK: boolean=false;
  onSubmit(){
    const _controls = this.formMACRO.controls; 
		if (this.formMACRO.invalid) {
			Object.keys(_controls).forEach(controlName =>
        _controls[controlName].markAsTouched()
      );
      this.toastr.warningToastr('Ingrese los campos obligatorios.', 'Advertencia!', {
        toastTimeout: 2000,
        showCloseButton: true,
        animate: 'fade',
        progressBar: true
      });
      return;
    }else{
      if(this.formMACRO.controls['NewPassRepeat'].value!=this.formMACRO.controls['NewPass'].value){
        this.toastr.warningToastr('Las Contraseña Nueva y su repetición no coinciden', 'Advertencia!', {
          toastTimeout: 2000,
          showCloseButton: true,
          animate: 'fade',
          progressBar: true
        });
        this.formMACRO.controls['NewPassRepeat'].setValue(null); this.formMACRO.controls['NewPass'].setValue(null);
        Object.keys(_controls).forEach(controlName =>
          _controls[controlName].markAsTouched()
        );
        return;
      }else{

        this.viewLoading = true; this.LOCK=true;
        this.dashboardIT_service.validate_old_password(this.glb_usuario,this.formMACRO.controls['ActualPass'].value,this.glb_ruc).subscribe(
          (data: any) => {
            if(data.Resultado==0){
              this.viewLoading = false; this.LOCK=false;
              this.toastr.warningToastr('El campo "Contraseña Actual" no coincide con su contraseña actual', 'Advertencia!', {
                toastTimeout: 2000,
                showCloseButton: true,
                animate: 'fade',
                progressBar: true
              });
            }else{
              
              this.dashboardIT_service.update_password(this.glb_usuario,this.formMACRO.controls['NewPass'].value,this.glb_ruc).subscribe(
                (data: any) => {
                  this.toastr.successToastr('Se ha actualizado su contraseña...', 'Éxito!', {
                    toastTimeout: 2000,
                    showCloseButton: true,
                    animate: 'fade',
                    progressBar: true
                  });
                  localStorage.setItem('TipoClave','U');
                  this.dialogRef.close(true);
                }, ( errorServicio ) => {
                  console.log(errorServicio)
                }
              );
            }
          }, ( errorServicio ) => {
            console.log(errorServicio)
          }
        );
      }
    }
    return;
  }




  onYesClick() {
    this.viewLoading = true;
    this.dialogRef.close(true);
  }
  onNoClick(): void {
		this.dialogRef.close();
  }

  public ActualPass: FormControl = new FormControl();
  public NewPass: FormControl = new FormControl();
  public NewPassRepeat: FormControl = new FormControl();
  createForm() {
    this.formMACRO = this._formBuilder.group({
      ActualPass: [null, Validators.required],
      NewPass: [null, Validators.required],
      NewPassRepeat: [null, Validators.required]
    });
  }

  


}
