import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuAsideDirective } from '../config/_core/directives/menu-aside.directive';
import { MenuAsideOffcanvasDirective } from '../config/_core/directives/menu-aside-offcanvas.directive';
import { MenuHorizontalOffcanvasDirective } from '../config/_core/directives/menu-horizontal-offcanvas.directive';
import { MenuHorizontalDirective } from '../config/_core/directives/menu-horizontal.directive';
import { ScrollTopDirective } from '../config/_core/directives/scroll-top.directive';
import { HeaderDirective } from '../config/_core/directives/header.directive';
import { MenuAsideToggleDirective } from '../config/_core/directives/menu-aside-toggle.directive';
import { JoinPipe } from '../config/_core/pipes/join.pipe';
import { GetObjectPipe } from '../config/_core/pipes/get-object.pipe';
import { PortletDirective } from '../config/_core/directives/portlet.directive';

@NgModule({
	imports: [CommonModule],
	declarations: [
		// directives
		MenuAsideDirective,
		MenuAsideOffcanvasDirective,
		MenuHorizontalOffcanvasDirective,
		MenuHorizontalDirective,
		ScrollTopDirective,
		HeaderDirective,
		MenuAsideToggleDirective,
		PortletDirective,
		// pipes
		JoinPipe,
		GetObjectPipe,
	],
	exports: [
		// directives
		MenuAsideDirective,
		MenuAsideOffcanvasDirective,
		MenuHorizontalOffcanvasDirective,
		MenuHorizontalDirective,
		ScrollTopDirective,
		HeaderDirective,
		MenuAsideToggleDirective,
		PortletDirective,
		// pipes
		JoinPipe,
		GetObjectPipe,
	],
	providers: []
})
export class ConfigModule {}
