import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigModule } from '../../../../../config/config.module';
import {
	MatProgressSpinnerModule,
	MatProgressBarModule
} from '@angular/material';

import { PorletCabeceraComponent } from './porlet-cabecera.component';


@NgModule({
	imports: [
		CommonModule,
		ConfigModule,
		MatProgressSpinnerModule,
		MatProgressBarModule
	],
	declarations: [PorletCabeceraComponent],
	exports: [PorletCabeceraComponent]
})
export class PortletCabeceraModule {}
