import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';

import { NewPasswordComponent } from '../_modal/new-password/new-password.component';

@Injectable({
  providedIn: 'root'
})
export class UtilsTOPService {

  constructor(
    private dialog: MatDialog
  ) { }

    _update_system_password(title: string = '', description: string = '', waitDesciption: string = '') {
        return this.dialog.open(NewPasswordComponent, {
            data: { title, description, waitDesciption},
            width: '400px'
        });
    }
    
  

}
