import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { AsideLeftComponent } from './aside/aside-left.component';
import { FooterComponent } from './footer/footer.component';
import { SubheaderComponent } from './subheader/subheader.component';
import { BrandComponent } from './header/brand/brand.component';
import { MenuSectionComponent } from './aside/menu-section/menu-section.component';
import { TopbarComponent } from './header/topbar/topbar.component';
import { ConfigModule } from '../../config/config.module';
import { UserProfileComponent } from './header/topbar/user-profile/user-profile.component';
//import { SearchDropdownComponent } from './header/topbar/search-dropdown/search-dropdown.component';
import { NotificationComponent } from './header/topbar/notification/notification.component';
import { QuickActionComponent } from './header/topbar/quick-action/quick-action.component';
import { MenuHorizontalComponent } from './header/menu-horizontal/menu-horizontal.component';
import { AsideRightComponent } from './aside/aside-right/aside-right.component';
import { SearchDefaultComponent } from './header/topbar/search-default/search-default.component';
import { HeaderSearchComponent } from './header/header-search/header-search.component';
import { LanguageSelectorComponent } from './header/topbar/language-selector/language-selector.component';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { NgbModule, NgbAlertConfig } from '@ng-bootstrap/ng-bootstrap';
import { MatProgressBarModule, MatTabsModule, MatButtonModule, MatTooltipModule, MatIconModule, MatInputModule, MAT_DIALOG_DEFAULT_OPTIONS, MatFormFieldModule, MatExpansionModule, MatDividerModule, MatToolbarModule, MatStepperModule, MatAutocompleteModule, MatDatepickerModule, MatRadioModule, MatCardModule, MatNativeDateModule, MatDialogModule, MatCheckboxModule, MatMenuModule, MatSelectModule, MatProgressSpinnerModule, MatPaginatorModule, MatSortModule, MatTableModule} from '@angular/material';

import { TranslateModule } from '@ngx-translate/core';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {NewPasswordComponent} from './_modal/new-password/new-password.component'
import {UtilsTOPService} from './_service/UtilsService.service';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
	// suppressScrollX: true
};




@NgModule({
	declarations: [
		HeaderComponent,
		FooterComponent,
		SubheaderComponent,
		BrandComponent,

		// topbar components
		TopbarComponent,
		UserProfileComponent,
		//SearchDropdownComponent,
		NotificationComponent,
		QuickActionComponent,
		LanguageSelectorComponent,

		// aside left menu components
		AsideLeftComponent,
		MenuSectionComponent,

		// horizontal menu components
		MenuHorizontalComponent,

		// aside right component
		AsideRightComponent,

		SearchDefaultComponent,

		HeaderSearchComponent,NewPasswordComponent

	],
	exports: [
		HeaderComponent,
		FooterComponent,
		SubheaderComponent,
		BrandComponent,

		// topbar components
		TopbarComponent,
		UserProfileComponent,
		//SearchDropdownComponent,
		NotificationComponent,
		QuickActionComponent,
		LanguageSelectorComponent,

		// aside left menu components
		AsideLeftComponent,
		// MenuSectionComponent,

		// horizontal menu components
		MenuHorizontalComponent,

		// aside right component
		AsideRightComponent
	],
	providers: [
		/*{
			provide: PERFECT_SCROLLBAR_CONFIG,
			useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
		},*/
		NgbAlertConfig,
		{
			provide: MAT_DIALOG_DEFAULT_OPTIONS,
			useValue: {
				hasBackdrop: true,
				panelClass: 'm-mat-dialog-container__wrapper',
				height: 'auto',
				width: '900px'
			}
		},UtilsTOPService
	],
	entryComponents:[NewPasswordComponent],
	imports: [
		CommonModule,
		RouterModule,
		ConfigModule,
		PerfectScrollbarModule,
		NgbModule,
		FormsModule,ReactiveFormsModule,
		MatProgressBarModule,
		MatTabsModule,
		MatButtonModule,MatIconModule,
		MatTooltipModule,
		TranslateModule.forChild(),
		LoadingBarModule.forRoot(),
		MatFormFieldModule,

		MatInputModule,
		MatIconModule,
		MatProgressBarModule,
		MatButtonModule,
		MatTabsModule,


		MatTableModule,
		MatPaginatorModule,
		MatSortModule,
		MatProgressSpinnerModule,

		MatSelectModule,
		MatMenuModule,

		MatCheckboxModule,
		MatDialogModule,

		MatNativeDateModule,
		MatCardModule,
		MatRadioModule,
		MatDatepickerModule,
		MatAutocompleteModule,

		MatStepperModule,
		MatToolbarModule, MatDividerModule, MatExpansionModule,
	]
})
export class LayoutModule {}
