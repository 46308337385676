
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../../../../environments/environment';
import { Observable } from 'rxjs';
import { NAMED_ENTITIES } from '@angular/compiler';

@Injectable({
  providedIn: 'root'
})
export class Dashboard_ITService {
    head: HttpHeaders;
    constructor(
        private http: HttpClient,
    ) {
        this.head = new HttpHeaders();
        this.head = this.head.append('Authorization', 'Basic ' + btoa(environment.UsrAccessAuthIT + ':' + environment.PassAccessAuthIT));
    }

    public validate_old_password(usser: string, password: string, Ruc: string){
        return this.http.get(`${environment.baseUrlIT}/Seguridad/ZITG_ValidatePassword_ENCRYPTING?usr=${usser}&pass=${password}&rucempresa=${Ruc}`, 
        { headers: this.head }).pipe( map( data => data ));
    }

    public update_password(usser: string, password: string, Ruc: string) {
        return this.http.get(`${environment.baseUrlIT}/Seguridad/ZITS_UpdatePassword_ENCRYPTING?usr=${usser}&pass=${password}&rucempresa=${Ruc}`, 
        { headers: this.head }).pipe( map( data => data ));
    }

    
}

