import { BehaviorSubject, Observable, Subject, from, throwError } from 'rxjs';
import { map, catchError, tap, switchMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { AuthService } from 'ngx-auth';

import { TokenStorage, SessionKeys } from './token-storage.service';
import { UtilsService } from '../services/utils.service';
import { AccessData } from '../interfaces/access-data';
import { Credential } from '../interfaces/credential';
import { environment } from '../../../../../../environments/environment';

@Injectable()
export class AuthenticationService implements AuthService {
	API_URL = 'api';
	API_ENDPOINT_LOGIN = '/login';
	API_ENDPOINT_REFRESH = '/refresh';
	API_ENDPOINT_REGISTER = '/register';

	public onCredentialUpdated$: Subject<AccessData>;

	head: HttpHeaders;
	constructor(
		private http: HttpClient,
		private tokenStorage: TokenStorage,
		private util: UtilsService
	) {
		this.onCredentialUpdated$ = new Subject();
		this.head = new HttpHeaders();
        this.head = this.head.append('Authorization', 'Basic ' + btoa(environment.UsrAccessAuthIT + ':' + environment.PassAccessAuthIT));
	}

	/**
	 * Check, if user already authorized.
	 * @description Should return Observable with true or false values
	 * @returns {Observable<boolean>}
	 * @memberOf AuthService
	 */
	public isAuthorized(): Observable<boolean> {
		return this.tokenStorage.getAccessToken().pipe(map(token => !!token));
	}

	/**
	 * Get access token
	 * @description Should return access token in Observable from e.g. localStorage
	 * @returns {Observable<string>}
	 */
	public getAccessToken(): Observable<string> {
		return this.tokenStorage.getAccessToken();
	}

	/**
	 * Get user roles
	 * @returns {Observable<any>}
	 */
	public getUserRoles(): Observable<any> {
		return this.tokenStorage.getUserRoles();
	}

	/**
	 * Function, that should perform refresh token verifyTokenRequest
	 * @description Should be successfully completed so interceptor
	 * can execute pending requests or retry original one
	 * @returns {Observable<any>}
	 */
	public refreshToken(): Observable<AccessData> {
		return this.tokenStorage.getRefreshToken().pipe(
			switchMap((refreshToken: string) => {
				return this.http.get<AccessData>(this.API_URL + this.API_ENDPOINT_REFRESH + '?' + this.util.urlParam(refreshToken));
			}),
			tap(this.saveAccessData.bind(this)),
			catchError(err => {
				this.logout();
				return throwError(err);
			})
		);
	}

	/**
	 * Function, checks response of failed request to determine,
	 * whether token be refreshed or not.
	 * @description Essentialy checks status
	 * @param {Response} response
	 * @returns {boolean}
	 */
	public refreshShouldHappen(response: HttpErrorResponse): boolean {
		return response.status === 401;
	}

	/**
	 * Verify that outgoing request is refresh-token,
	 * so interceptor won't intercept this request
	 * @param {string} url
	 * @returns {boolean}
	 */
	public verifyTokenRequest(url: string): boolean {
		return url.endsWith(this.API_ENDPOINT_REFRESH);
	}

	/**
	 * Submit login request
	 * @param {Credential} credential
	 * @returns {Observable<any>}
	 */
	public login(Login: string, Usuario: string, Dominio: string, Password: string): Observable<any> {
		// {'id':1,'username':'admin','password':'demo','email':'admin@demo.com','accessToken':'access-token-0.022563452858263444','refreshToken':'access-token-0.9348573301432961','roles':['ADMIN'],'pic':'./assets/app/media/img/users/user4.jpg','fullname':'Mark Andre'};
		// return this.http.get<AccessData>(`/WS_SG/api/login/login?Login=${Login}&Usuario=${Usuario}&Dominio=${Dominio}&Contrasena=${Password}`).pipe(
		// return this.http.get<AccessData>('http://localhost:65222/api/Seguridad/ZITG_LoginANG', {
		let me = this;
		return this.http.get<AccessData>(`${environment.baseUrlAutentication}/Seguridad/ZITG_LoginAgro`, {
			headers: this.head,
			params: {
				prmstrLogin: Usuario,
				prmstrPassword: Password,
				prmstrDominio: Dominio
			}
		}).pipe(
		/*tap(response => {
			if(response instanceof Array){
				this.tokenStorage.set(SessionKeys.NOMBRE, response[0].Nombre);
				return response.pop();
			}
			return response; })*/
			map((result: any) => {
				if (result instanceof Array) {
					try {
						if(result[0].Autenticado){
							return result.pop();
						}else{
							return undefined;
						}
					} catch (error) {
						return undefined;
					}
				} else {
					return undefined;
				}
			}),
			tap(this.saveAccessData.bind(this)),
			catchError(this.handleError('login', []))
		);
	}

	public getAccesosDNP(sistema: string) {
		return this.http.get(`${environment.baseUrlAutentication}/Seguridad/ZITG_ObtenerOpcionesUsuario?prmintUserId=${localStorage.getItem('usuarioid')}&prmstrApplication=${sistema}`,{ headers: this.head }).pipe(map(data => data));
	}

	public getDatoSession(key: any) {
		return this.tokenStorage.get(key);
	}

	/**
	 * Handle Http operation that failed.
	 * Let the app continue.
	 * @param operation - name of the operation that failed
	 * @param result - optional value to return as the observable result
	 */
	private handleError<T>(operation = 'operation', result?: any) {
		return (error: any): Observable<any> => {
			// TODO: send the error to remote logging infrastructure
			console.error(error); // log to console instead

			// Let the app keep running by returning an empty result.
			return from(result);
		};
	}

	/**
	 * Logout
	 */
	public logout(refresh?: boolean): void {
		this.tokenStorage.clear();
		//if (refresh) {
		//	location.reload(true);
		//}
	}

	/**
	 * Save access data in the storage
	 * @private
	 * @param {AccessData} data
	 */
	private saveAccessData(accessData: AccessData) {
		//console.log(accessData.TipoClave);
		if (typeof accessData !== 'undefined') {
			this.tokenStorage.set(SessionKeys.USUARIOID, accessData.IdUsuario);
			this.tokenStorage.set(SessionKeys.USUARIO, accessData.Login);
			this.tokenStorage.set(SessionKeys.NOMBRE, accessData.Nombre);
			this.tokenStorage.set(SessionKeys.RUC, accessData.RUCEmpresa);
			this.tokenStorage.set(SessionKeys.AREA, accessData.DesUnidadOrganizativa);
			this.tokenStorage.set(SessionKeys.CECO, accessData.CentroCosto);
			this.tokenStorage.set(SessionKeys.EMPRESA, accessData.Empresa);
			this.tokenStorage.set('Latitud',accessData.Latitud);
			this.tokenStorage.set('Longitud',accessData.Longitud);
			this.tokenStorage.setEmpresaID(accessData.EmpresaId);
			this.tokenStorage.setAccessRol("2");
			this.tokenStorage.setUserRoles("[\"ADMIN\"]");
			localStorage.setItem('Accesos', null);
			localStorage.setItem('TipoClave', accessData.TipoClave)
			localStorage.setItem('DefaultPassword', (accessData.DefaultPassword?"1":"0"))
			/*this.tokenStorage
				.setAccessEmpresa(accessData.empresa)
				.setAccessNombres(accessData.nombres)
				.setAccessEmail(accessData.correo)
				.setAccessUsuario(accessData.cuentaUsuario)
				.setAccessRol(accessData.rol)
				.setAccessToken(accessData.accessToken)
				.setRefreshToken(accessData.refreshToken)
				.setUserRoles(accessData.roles);
				--.setAccessEmpresa(accessData.sociedad)
				.setAccessNombres(accessData.Nombre)
				.setAccessEmail(accessData.email)
				.setAccessUsuario(accessData.login)
				.setAccessRol('2')
				.setAccessToken(accessData.accessToken)
				.setRefreshToken(accessData.refreshToken)
				.setUserRoles('[\'ADMIN\']');*/
			this.onCredentialUpdated$.next(accessData);
		}
	}

	/**
	 * Submit registration request
	 * @param {Credential} credential
	 * @returns {Observable<any>}
	 */
	public register(credential: Credential): Observable<any> {
		// dummy token creation
		credential = Object.assign({}, credential, {
			accessToken: 'access-token-' + Math.random(),
			refreshToken: 'access-token-' + Math.random(),
			roles: ['USER'],
		});
		return this.http.post(this.API_URL + this.API_ENDPOINT_REGISTER, credential)
			.pipe(catchError(this.handleError('register', []))
		);
	}

	/**
	 * Submit forgot password request
	 * @param {Credential} credential
	 * @returns {Observable<any>}
	 */
	public requestPassword(credential: Credential): Observable<any> {
		return this.http.get(this.API_URL + this.API_ENDPOINT_LOGIN + '?' + this.util.urlParam(credential))
			.pipe(catchError(this.handleError('forgot-password', []))
		);
	}

}
