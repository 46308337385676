export const environment = {
  production: false,
  isMockEnabled: true,
  codigoSistema: '0002',

  /*
  baseUrlAutentication: 'http://localhost:3020/api',//'https://demosamagrobrainapi.azurewebsites.net/WS_IT/api',///WS_IT/api
  
  baseUrlPS: 'http://localhost:3022/api',//'https://demosamagrobrainapi.azurewebsites.net/WS_AB/api', //http://localhost:26623/api --> WS_AB/api
  baseUrlGCH: 'http://localhost:3021/api',//'https://demosamagrobrainapi.azurewebsites.net/WS_GCH/api', //http://localhost:50398/api --> /WS_GCH/api
  baseUrlIT: 'http://localhost:3020/api',//'https://demosamagrobrainapi.azurewebsites.net/WS_IT/api',
              */
  /*
  baseUrlAutentication: 'http://etqas.eastus2.cloudapp.azure.com:9035/WS_IT/api',///WS_IT/api
  
  baseUrlPS: 'http://etqas.eastus2.cloudapp.azure.com:9035/WS_AB/api', //http://localhost:26623/api --> WS_AB/api
  baseUrlGCH: 'http://etqas.eastus2.cloudapp.azure.com:9035/WS_GCH/api', //http://localhost:50398/api --> /WS_GCH/api
  baseUrlIT: 'http://etqas.eastus2.cloudapp.azure.com:9035/WS_IT/api',*/
               

  baseUrlAutentication: 'https://demosamagrobrainapi.azurewebsites.net/WS_IT/api',///WS_IT/api
  
  baseUrlPS: 'https://demosamagrobrainapi.azurewebsites.net/WS_AB/api', //http://localhost:26623/api --> WS_AB/api
  baseUrlGCH: 'https://demosamagrobrainapi.azurewebsites.net/WS_GCH/api', //http://localhost:50398/api --> /WS_GCH/api
  baseUrlIT: 'https://demosamagrobrainapi.azurewebsites.net/WS_IT/api',
               
  
  apiKeyGOOGLE_MAPS : 'AIzaSyAqWXYX7vIWHqTnDIPNaf1Ys_JzUMD11gA',

  // User AB
  UsrAccessAuthAB: '4gr1Br4!nMS',
  PassAccessAuthAB: '486275913',

  // User IT
  UsrAccessAuthIT: '4gr1Br4!nMS_1T',
  PassAccessAuthIT: '167943825',  

  // User GCH
  UsrAccessAuthHCM: '4gr1Br4!nMS_HcM',
  PassAccessAuthHCM: '861743815',

  firebase: {
    apiKey: 'AIzaSyDlKzlt2sRQyKrhQWbZAsUy7whUoR1ERq8',
    authDomain: 'sam-bayer.firebaseio.com"',
    databaseURL: 'https://sam-bayer.firebaseio.com',
    projectId: 'sam-bayer',
    storageBucket: 'sam-bayer.appspot.com',
    messagingSenderId: '672147703137'
  },
  firebase_email : 'dev.sambayer.agrobrain@gmail.com',
  firebase_pass : 's@m2021@gr0br@1n',
  firebase_UID: '3RYkPA1o9vhGqkDUTCbZh23iITD2',//opcional..creo

};
//npm install firebase@6.5.0 @angular/fire@5.2.1 --save
