import { AuthenticationService } from '../../../../pages/auth/_core/services/authentication.service';
import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { SessionKeys } from '../../../../pages/auth/_core/services/token-storage.service';
import { UtilsTOPService } from '../../../_service/UtilsService.service';

@Component({
	selector: 'm-user-profile',
	templateUrl: './user-profile.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserProfileComponent implements OnInit {
	@HostBinding('class')
	// tslint:disable-next-line:max-line-length
	classes = 'm-nav__item m-topbar__user-profile m-topbar__user-profile--img m-dropdown m-dropdown--medium m-dropdown--arrow m-dropdown--header-bg-fill m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--skin-light';

	@HostBinding('attr.m-dropdown-toggle') attrDropdownToggle = 'click';

	@Input() avatar: string = './assets/app/media/img/users/user-4.png';
	@Input() avatarBg: SafeStyle = '';
	@Input() usuarioName: string = '';
	@Input() usuarioMail: string = '';

	@ViewChild('mProfileDropdown') mProfileDropdown: ElementRef;

	constructor (
		private router: Router,
		private authService: AuthenticationService,
		private sanitizer: DomSanitizer,
		private utilsService: UtilsTOPService
	) {}

	ngOnInit (): void {
		if (!this.avatarBg) {
			this.avatarBg = this.sanitizer.bypassSecurityTrustStyle('url(./assets/app/media/img/misc/user_profile_bg.png)');
		}
		this.usuarioName = this.authService.getDatoSession(SessionKeys.NOMBRE);
		this.usuarioMail = this.authService.getDatoSession(SessionKeys.USUARIO);
	}

	public logout () {
		this.authService.logout(true);
		this.router.navigate(['/']);
		location.reload();
	}

	open_modal_change_password(){
		let _title = 'Cambiar contraseña';
		let _description = '';
		let _waitDesciption = 'Actualizando contraseña...';
	
		const dialogRef = this.utilsService._update_system_password(_title, _description, _waitDesciption);
		dialogRef.afterClosed().subscribe(res => {
			if (!res) {
				return;
			} else {
				return;
			}
		})
	}
}
